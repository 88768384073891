// Bootstrap v4 overrides

$enable-rounded: false;
// $grid-gutter-width: 2.5rem;

// Colors


$primary: #009e75;
$primary-light: #cde7e1;
$signal: #df2649;

$orange: hsl(35, 84%, 62%);
$green: $primary;
$red: $signal;
$turquoise: hsl(173, 85%, 39%);
$teal: hsl(180, 75%, 49%);
$blue: hsl(196, 91%, 45%);
$dark-blue: #005597;
$dark-green: hsl(84, 67%, 36%);
$light-blue: hsl(204, 86%, 86%);
$light-green: hsl(81, 80%, 79%);
$alt-grey: hsl(220, 2%, 38%);

$font-color: hsl(210, 15%, 25%);
$body-color: $font-color;

$black: #000;
$white: #fff;

$alert-yellow: #e9e700;
$alert-red: #ff4f71;
$alert-green: $primary;
$alert-blue: #00d5f0;
$pretty-pink: #e99ed2;
$bomb-orange: #e8980c;

$dark-theme-background: #191919;
$dark-theme-font-color: #dfdfdf;


$theme-colors: (
  "white": $white,
  "orange": $orange,
  "green": $green,
  "turquoise": $turquoise,
  "teal": $teal,
  "blue": $blue,
  "dark-blue": $dark-blue,
  "dark-green": $dark-green,
  "light-blue": $light-blue,
  "light-green": $light-green,
  "alt-grey": $alt-grey,
  "primary": $primary,
  "primary-light": $primary-light,
  // "signal-1-light": $signal-1-light,
  "body": $body-color
);

$link-color: $body-color;
$link-hover: $green;
$navbar-font-color: $body-color;
$footer-link-color: rgba(255,255, 255, 0.7);
$footer-link-hover: $white;

// Fonts

@import url("//fonts.googleapis.com/css?family=Fira+Sans+Extra+Condensed:500|Nunito+Sans:200,400,600|Material+Icons|Material+Icons+Outlined");

$font-family-sans-serif: "Nunito Sans", -apple-system, system-ui,
  BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;
$font-weight-normal: 200;
$font-weight-bold: 700;

$headings-line-height: 1;
$display-line-height: 0.86em;
$btn-font-size: 1rem;
$btn-font-size-lg: 1.15rem;

// Navbar

$navbar-padding-y: .5rem;
// $navbar-padding-x: 2.5rem;
$nav-link-padding: 1em;
$navbar-light-toggler-border: transparent;
$navbar-light-color: transparentize($body-color, 0.2);
$navbar-light-hover-color: $turquoise;
$navbar-light-active-color: $dark-blue;

$dropdown-link-active-bg: transparentize($green, 0.7);

// Jumbotron

$jumbotron-bg: transparent;
$jumbotron-padding: 0;

// Utilities

$default-transition: .25s ease-out;


// Import Bootstrap
@import "~bootstrap/scss/bootstrap";

.container-fluid-max {
  @extend .container-fluid;
  max-width: 1140px !important;
}

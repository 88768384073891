@import "styles/shared.scss";

@mixin make-label($color) {
  .large-label {
    color: transparentize($body-color, .6);
    border: 1px solid transparentize($color, .8);
    background-color: transparentize($color, .95);
    &:not(.disabled):hover {
      color: transparentize($color, .5);
    }
    &.disabled {
      opacity: 1;
    }
  }
  &.active {
    color: $color;
    .large-label {
      color: $color;
      border-color: $color;
      background-color: transparentize($color, .8);
    }
  }
  &.small-label:not(.disabled):hover {
    color: transparentize($color, .5);
  }
}

.label {
  color: transparentize($body-color, .6);
  &.positive {
    @include make-label($green);
  }
  &.doubt {
    @include make-label($orange);
  }
  &.negative {
    @include make-label($red);
  }
  transition: color .15s linear;
  will-change: color;
  &.disabled {
    opacity: 1;
  }
}

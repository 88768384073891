@import 'styles/shared.scss';
@import '~react-toastify/dist/ReactToastify.css';

.default-toast {
  @extend .p-0;
  font-family: $font-family-sans-serif !important;
  position: relative;

  &[class*="error"] {
    background-color: $danger !important;
  }

  > [class*="toast-body"] {
    padding: $spacer $spacer / 2 $spacer $spacer;
  }

  > .close-button {
    padding: .2em .2em 0 0;
    opacity: .7;
    transition: opacity $default-transition;
    will-change: opacity;
  }

}
